import { useEffect, useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import BlogReplyTree from "./BlogReplyTree";
import BlogReply from "./BlogReply";

type BlogComment = {
  id: number;
  uuid: string;
  username: string;
  content: string;
  parent_id: number | null;
  created_at: string;
  deleted?: boolean;
  edited?: boolean;
  reputation?: number;
};

type BlogCommentSectionProps = {
  slug: string;
};

const BlogCommentSection = ({ slug }: BlogCommentSectionProps) => {
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState<BlogComment[]>([]);
  const [replies, setReplies] = useState<BlogComment[]>([]);
  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [replyInput, setReplyInput] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState<"top" | "newest" | "oldest">("newest");
  const [totalComments, setTotalComments] = useState(0);

  useEffect(() => {
    fetchComments(1, sort, true);
  }, [slug, sort]);

  const fetchComments = async (pageNum = 1, sortType = sort, reset = false) => {
    if (pageNum > totalPages && !reset) return;
    setLoading(true);
    const res = await fetch(`/api/blog/comments/${slug}?sort=${sortType}&page=${pageNum}`);
    const json = await res.json();
    if (res.ok) {
      if (reset) {
        setComments(json.comments);
      } else {
        setComments(prev => [...prev, ...json.comments]);
      }
      setReplies(json.replies);
      setTotalPages(json.totalPages);
      setTotalComments(json.totalComments || 0);
      setPage(json.currentPage);
    }
    setLoading(false);
  };

  const handlePostReply = async () => {
    if (!replyInput.trim()) return;
    setPosting(true);
    const res = await fetch(`/api/blog/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify({
        slug,
        content: replyInput,
        parent_id: null,
      }),
    });
    if (res.ok) {
      setReplyInput("");
      fetchComments(1, sort, true);
    } else {
      alert("Failed to post comment.");
    }
    setPosting(false);
  };

  // Map children
  const childMap: Record<number, BlogComment[]> = {};
  replies.forEach((c) => {
    if (!childMap[c.parent_id!]) childMap[c.parent_id!] = [];
    childMap[c.parent_id!].push(c);
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-300">
          {totalComments} Comment{totalComments !== 1 && "s"}
        </h3>
        <select
          value={sort}
          onChange={(e) => {
            setSort(e.target.value as "top" | "newest" | "oldest");
            setComments([]);
          }}
          className="bg-[#1f1f1f] border border-gray-600 text-gray-200 text-sm rounded px-2 py-1"
        >
          <option value="top">Top</option>
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      {user && (
        <div className="mb-6">
          <BlogReply
            slug={slug}
            parentId={null}
            value={replyInput}
            setValue={setReplyInput}
            onReplyPosted={() => fetchComments(1, sort, true)}
            posting={posting}
            onCancel={() => setReplyInput("")}
          />
        </div>
      )}

      {loading && page === 1 ? (
        <p className="text-gray-400">Loading comments...</p>
      ) : (
        <>
          <BlogReplyTree
            replies={comments}
            childReplies={childMap}
            depth={0}
            slug={slug}
            onRefresh={() => fetchComments(1, sort, true)}
          />
          {page < totalPages && (
            <div className="text-center mt-4">
              <button
                onClick={() => fetchComments(page + 1)}
                className="text-purple-400 hover:underline text-sm"
              >
                Load more comments ({totalComments - comments.length} remaining)
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BlogCommentSection;
