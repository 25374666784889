import { useContext, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import mentionStyle from "../styles/mentionStyle";
import AuthContext from "../context/AuthContext";

type BlogReplyProps = {
  slug: string;
  parentId: number | null;
  onReplyPosted: () => void;
  onCancel: () => void;
  commentId?: number;
  editing?: boolean;
  initialContent?: string;
  value?: string;
  setValue?: (value: string) => void;
  posting?: boolean;
};

const BlogReply = ({
  slug,
  parentId,
  onReplyPosted,
  onCancel,
  commentId,
  editing = false,
  initialContent = "",
  value,
  setValue,
  posting,
}: BlogReplyProps) => {
  const { user } = useContext(AuthContext);

  const [internalContent, setInternalContent] = useState(value !== undefined ? value : initialContent);
  const [error, setError] = useState("");
  const [localSubmitting, setLocalSubmitting] = useState(false);

  const content = value !== undefined ? value : internalContent;
  const isSubmitting = posting || localSubmitting;

  const handleSubmit = async () => {
    if (!content.trim()) return;
    setError("");

    const endpoint = editing
      ? `/api/blog/comments/${commentId}`
      : `/api/blog/${slug}/comments`;
    const method = editing ? "PUT" : "POST";

    try {
      if (!posting) setLocalSubmitting(true);

      const res = await fetch(endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
          ...(user?.token ? { Authorization: `Bearer ${user.token}` } : {}),
        },
        body: JSON.stringify({
          content,
          parent_id: parentId,
        }),
      });

      if (!res.ok) throw new Error("Failed to submit");

      if (setValue) setValue("");
      else setInternalContent("");

      onReplyPosted?.();
      onCancel?.();
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Try again.");
    } finally {
      if (!posting) setLocalSubmitting(false);
    }
  };

  return (
    <div className="bg-[#1f1f1f] border border-gray-700 rounded-lg p-4 space-y-3">
      {error && <p className="text-red-400 text-sm">{error}</p>}

      <MentionsInput
        value={content}
        onChange={(e) =>
          setValue ? setValue(e.target.value) : setInternalContent(e.target.value)
        }
        className="w-full min-h-[6rem] p-2 rounded text-white bg-gray-800 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
        style={mentionStyle}
        placeholder="Write a comment..."
        maxLength={3000}
      >
        <Mention
          trigger="@"
          data={async (search, callback) => {
            try {
              const res = await fetch(`/api/users/search?query=${search}`);
              const json = await res.json();
              callback(json.map((u: any) => ({ id: u.username, display: u.username })));
            } catch {
              callback([]);
            }
          }}
          style={mentionStyle}
        />
      </MentionsInput>

      <div className="flex gap-2 justify-end">
        {onCancel && (
          <button
            onClick={onCancel}
            className="px-3 py-1 rounded bg-gray-700 text-gray-300 hover:bg-gray-600 text-sm"
          >
            Cancel
          </button>
        )}
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-500 transition text-sm font-semibold disabled:opacity-50"
        >
          {editing ? "Save Changes" : "Post Comment"}
        </button>
      </div>
    </div>
  );
};

export default BlogReply;
