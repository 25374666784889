import { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import BlogReply from "./BlogReply";

type BlogComment = {
  id: number;
  username: string;
  uuid: string;
  content: string;
  parent_id: number | null;
  created_at: string;
  deleted?: boolean;
  edited?: boolean;
  reactions?: Record<string, number>;
};

type Props = {
  replies: BlogComment[];
  childReplies: Record<number, BlogComment[]>;
  depth: number;
  slug: string;
  onRefresh: () => void;
};

const reactionTypes: { type: string; emoji: string }[] = [
  { type: "like", emoji: "👍" },
  { type: "love", emoji: "❤️" },
  { type: "fire", emoji: "🔥" },
  { type: "laugh", emoji: "😂" },
  { type: "wow", emoji: "😲" },
];

const BlogReplyTree = ({ replies, childReplies, depth, slug, onRefresh }: Props) => {
  const { user } = useContext(AuthContext);
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [reacting, setReacting] = useState<number | null>(null);

  const handleEdit = async (id: number, content: string) => {
    const res = await fetch(`/api/blog/comments/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content }),
    });
    if (res.ok) {
      setEditingId(null);
      onRefresh();
    } else {
      alert("Failed to edit comment.");
    }
  };

  const handleDelete = async (id: number) => {
    if (!confirm("Delete this comment?")) return;
    const res = await fetch(`/api/blog/comments/${id}`, { method: "DELETE" });
    if (res.ok) onRefresh();
    else alert("Failed to delete.");
  };

  const handleReaction = async (commentId: number, type: string) => {
    if (!user) return alert("You must be logged in to react.");
    setReacting(commentId);

    try {
      const res = await fetch(`/api/blog/comments/${commentId}/react`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ type }),
      });

      if (res.ok) {
        onRefresh();
      } else {
        alert("Failed to submit reaction.");
      }
    } catch {
      alert("Error reacting to comment.");
    } finally {
      setReacting(null);
    }
  };

  return (
    <div className="space-y-4">
      {replies.map((reply) => {
        const isReplying = replyingTo === reply.id;
        const isEditing = editingId === reply.id;
        const marginLeft = `${Math.min(depth, 3) * 16}px`;

        return (
          <div key={reply.id} className="text-sm" style={{ marginLeft }}>
            <div className="border-l border-gray-700 pl-4">
              <p className="text-gray-400 mb-1">
                <span className="text-white font-semibold">{reply.username}</span> ·{" "}
                {new Date(reply.created_at).toLocaleString()}
                {reply.edited && <span className="ml-2 italic text-gray-500">(edited)</span>}
              </p>

              {isEditing ? (
                <BlogReply
                  parentId={reply.parent_id}
                  slug={slug}
                  initialContent={reply.content}
                  editing
                  commentId={reply.id}
                  onReplyPosted={onRefresh}
                  onCancel={() => setEditingId(null)}
                />
              ) : (
                <div className="text-gray-200 whitespace-pre-wrap mb-2">
                  {reply.deleted ? (
                    <i className="text-gray-500">[Deleted content]</i>
                  ) : (
                    reply.content
                  )}
                </div>
              )}

              {!isEditing && !reply.deleted && (
                <>
                  <div className="flex gap-3 items-center text-xs text-gray-400 mb-1">
                    {user && (
                      <button
                        onClick={() => setReplyingTo(reply.id)}
                        className="hover:underline text-blue-400"
                      >
                        Reply
                      </button>
                    )}
                    {(user?.uuid === reply.uuid || user?.is_staff) && (
                      <>
                        <button
                          onClick={() => setEditingId(reply.id)}
                          className="hover:underline text-yellow-400"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(reply.id)}
                          className="hover:underline text-red-500"
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>

                  {/* Reaction bar */}
                  <div className="flex gap-2 text-sm text-gray-500 mb-1">
                    {reactionTypes.map(({ type, emoji }) => {
                      const count = reply.reactions?.[type] || 0;
                      return (
                        <button
                          key={type}
                          onClick={() => handleReaction(reply.id, type)}
                          className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs transition ${
                            reacting === reply.id
                              ? "opacity-50"
                              : "hover:bg-gray-700 hover:text-white"
                          }`}
                          disabled={reacting === reply.id}
                        >
                          <span>{emoji}</span>
                          <span>{count}</span>
                        </button>
                      );
                    })}
                  </div>
                </>
              )}

              {isReplying && (
                <div className="mt-2">
                  <BlogReply
                    parentId={reply.id}
                    slug={slug}
                    onReplyPosted={() => {
                      setReplyingTo(null);
                      onRefresh();
                    }}
                    onCancel={() => setReplyingTo(null)}
                  />
                </div>
              )}

              {childReplies[reply.id] && depth < 3 && (
                <BlogReplyTree
                  replies={childReplies[reply.id]}
                  childReplies={childReplies}
                  depth={depth + 1}
                  slug={slug}
                  onRefresh={onRefresh}
                />
              )}

              {childReplies[reply.id] && depth >= 3 && (
                <div className="text-xs text-gray-500 italic mt-2">
                  Further replies hidden to reduce nesting.
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlogReplyTree;
